import '../sass/project.scss';

/* Project specific Javascript goes here. */

import 'leaflet/dist/leaflet.css'; // Import Leaflet CSS
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css'; // Re-uses images from ~leaflet package
import L from 'leaflet'; // Import Leaflet JS
// Expose Leaflet globally
window.L = L;
import 'leaflet-defaulticon-compatibility'; // Fixes marker icons for Webpack projects


